<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <!--<div slot="info" v-if="paramsId">-->
      <!--<span class="mr-20">所属门店：{{form.storeName}}</span>-->
      <!--<span class="mr-20">创建人：{{form.createUserName}}</span>-->
      <!--<span class="mr-20">创建时间：{{form.createTime}}</span>-->
      <!--<span class="mr-20">最后编辑人：{{form.createUserName}}</span>-->
      <!--<span class="mr-20">最后编辑时间：{{form.createTime}}</span>-->
      <!--</div>-->
      <div slot="content">
        <a-card title="规则信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="规则名称" prop="title">
                  <a-input
                    v-model="form.title"
                    :maxLength="50"
                    :disabled="isDetail"
                    allowClear
                  ></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="达标金额" prop="amount">
                  <a-row :gutter="16">
                    <a-col :span="12">
                      <a-input-number
                        class="x-inner"
                        size="large"
                        v-model="form.amount"
                        allowClear
                        :disabled="isDetail"
                        :min="0"
                        :max="9999999999"
                      ></a-input-number>
                    </a-col>
                    <a-col :span="4">元 </a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="赠送金额" prop="amountDiscount">
                  <a-row :gutter="16">
                    <a-col :span="12">
                      <a-input-number
                        class="x-inner"
                        size="large"
                        v-model="form.amountDiscount"
                        allowClear
                        :disabled="isDetail"
                        :min="0"
                        :max="9999999999"
                      ></a-input-number>
                    </a-col>
                    <a-col :span="4">元 </a-col>
                  </a-row>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="优惠劵" prop="couponId">
                    <j-select-multiple v-model="form.couponId" :options="validCouponData" :readOnly="isDetail"/>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="有效类型" prop="periodType">
                  <a-select
                    v-model="form.periodType"
                    allowClear
                    :disabled="isDetail"
                  >
                    <a-select-option :value="0"> 长期 </a-select-option>
                    <a-select-option :value="1"> 自定义 </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row v-if="form.periodType === 1">
              <a-col :span="10">
                <a-form-model-item label="有效时间" prop="duration">
                  <a-range-picker
                    v-model="form.duration"
                    class="x-inner"
                    format="YYYY-MM-DD"
                    :disabled="isDetail"
                  ></a-range-picker>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="规则状态" prop="status">
                  <a-switch
                    checked-children="启用"
                    un-checked-children="禁用"
                    :checked="form.status === 0"
                    @change="handleChangeStatus"
                    :disabled="isDetail"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { rechargeRuleInfo, rechargeRuleSubmit } from '@/api/recharge';
import { rechargeRuleCount } from '@/libs/config';
import { getValidCouponList } from '@/api/coupon';
import formMixin from '@/mixin/formMixin';
import { formatDate, toMoment } from '@/libs/utils';
import JSelectMultiple from '@/components/JSelectMultiple';

export default {
  name: 'ChargeRuleForm',
  mixins: [formMixin],
  components: { JSelectMultiple },
  props: {},
  data() {
    return {
      rechargeRuleCount,
      validCouponData: [],
      form: {
        title: '',
        amount: '',
        amountDiscount: '',
        periodType: 0,
        duration: [],
        couponId: '',
        status: 0,
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入规则名称',
            trigger: 'blur',
          },
          // {
          //   min: 4,
          //   max: 50,
          //   message: '长度在4-50字之间',
          //   trigger: 'blur'
          // }
        ],
        amount: [
          {
            required: true,
            message: '请输入达标金额',
          },
        ],
        amountDiscount: [
          {
            required: true,
            message: '请输入赠送金额',
          },
        ],
        periodType: [
          {
            required: true,
            message: '请选择规则期限',
          },
        ],
        duration: [
          {
            required: true,
            message: '请选择有效时间',
          },
        ],
        status: [
          {
            required: true,
            message: '请选择规则状态',
          },
        ],
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          customRender: (text, row, index) => {
            return index + 1;
          },
        },
        {
          title: '充值大于（元）',
          dataIndex: 'amount',
          key: 'amount',
          customRender: (text, row, index) => {
            return (
              <section>
                <a-input-number
                  v-show={row.editable}
                  {...{
                    attrs: {
                      min: 1,
                      max: 9999999999,
                      value: text,
                    },
                  }}
                  vOn:change={(val) => this.handleChange(val, row, 1)}
                ></a-input-number>
                <span v-show={!row.editable}>{text}</span>
              </section>
            );
          },
        },
        {
          title: '赠送金额（元）',
          dataIndex: 'give',
          key: 'give',
          customRender: (text, row, index) => {
            return (
              <section>
                <a-input-number
                  v-show={row.editable}
                  {...{
                    attrs: {
                      min: 1,
                      max: 9999999999,
                      value: text,
                    },
                  }}
                  vOn:change={(val) => this.handleChange(val, row, 2)}
                ></a-input-number>
                <span v-show={!row.editable}>{text}</span>
              </section>
            );
          },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'action',
          width: 120,
          scopedSlots: { customRender: 'operation' },
        },
      ],
      dataList: [
        {
          editable: false,
          amount: 100,
          give: 30,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.loadValidCouponList();
    if (this.paramsId) {
      this.getData(this.paramsId);
    }

    // if (this.isDetail) {
    // this.columns.pop();
    // }
  },
  mounted() {},
  destroyed() {},
  methods: {
    // 获取信息
    getData() {
      rechargeRuleInfo({ shopRechargeRuleId: this.paramsId }).then((data) => {
        data.duration = [];
        this.form = data;
        this.form.duration = [toMoment(data.startDate), toMoment(data.endDate)];
      });
    },
    loadValidCouponList() {
      const that = this;
      this.validCouponData = [];
      console.log('loadValidCouponList');
      const param = {
        types: '1,2,3,4',
      };
      getValidCouponList(param).then((res) => {
        for (let i = 0; i < res.length; i++) {
          const item = {};
          item.value = res[i].id;
          item.label = res[i].name;
          that.validCouponData.push(item);
        }
      });
    },
    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
          };

          if (params.duration && params.duration.length) {
            params.startDate = formatDate(params.duration[0]);
            params.endDate = formatDate(params.duration[1]);
          }
          delete params.duration;
          params.status === params.status ? 1 : 0;
          rechargeRuleSubmit(params).then((res) => {
            this.$message.success(res.message || '操作成功');
            this.handleBack();
          });
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },

    // 新增规则
    handleAddRule() {
      if (this.dataList.length > rechargeRuleCount) {
        this.$message.error('最多只能添加10条规则');
        return;
      }
      this.dataList.push({
        editable: true,
        amount: 1,
        give: 1,
      });
    },
    handleChange(value, row, type) {
      if (type === 1) {
        row.amount = value;
      } else {
        row.give = value;
      }
    },
    handleEdit(row, index) {
      row.editable = true;
      row.cacheData = { ...row };
    },
    handleSave(index) {
      const current = this.dataList[index];
      current.editable = false;
      delete current.cacheData;
    },
    handleCancel(index) {
      const current = this.dataList[index];
      Object.assign(current, current.cacheData);
      current.editable = false;
      delete current.cacheData;
    },
    handleDelete(index) {
      this.dataList.splice(index, 1);
    },
    handleChangeStatus(status) {
      this.form.status = status ? 0 : 1;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
